<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="">
  <div class="spazio" style="display: flex; align-items: center; padding-left: 2rem;">

  </div>
  <div class="page-breadcrumb " >
    <div class=" d-flex align-items-center" style="height: 40px;">
      <h3 class="page-title" >{{currentUser.fullName}}&nbsp;&nbsp;-&nbsp;&nbsp;{{pageInfo?.title}}</h3>
      <div class="ml-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
              <li class="breadcrumb-item d-flex align-items-center" *ngIf="!last" [routerLink]="url.url">
                <a href='javascript:void(0)'>{{url.title}}</a>
              </li>
              <li class="breadcrumb-item active d-flex align-items-center" *ngIf="last">{{url.title}}</li>
            </ng-template>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>

<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
